<!--
 * @Author: 周晴龙
 * @Date: 2020-08-11 14:52:12
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-30 14:54:49
 * @FilePath: \user\src\views\businessTravel\order\query.vue
 * @Description:订单查询
-->
<template>
  <div class="order-query">
    <en-title-card name="订单查询">
      <div slot="right">
        <div class="button-export" @click="exportClick" style="display:none">
          <en-icon name="shangchuan" size="small"></en-icon>
          <div class="label">
            导出
          </div>
        </div>
      </div>
    </en-title-card>
    <en-tabs style="height: 0px;visibility: hidden !important;" v-model="currentTab" :list="tabList" type="white" @change="changeTabs"> </en-tabs>
    <en-table
      :data="tableData"
      :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
      :height-config="{ bottomHeight: 10 }"
      @filter-sort-change="handleFilterSortChange"
    >
      <en-table-column title="订单号" prop="orderId" :filter-sort-config="{ filterable: true }"></en-table-column>
      <en-table-column title="用车人" prop="userDeptName"  :filter-sort-config="{ filterable: true }"> </en-table-column>
      <en-table-column title="乘车人" prop="passengerName"  :filter-sort-config="{ filterable: true }"> </en-table-column>

      <en-table-column
        title="出发时间"
        prop="departureTime"
        width="180px"
        :filter-sort-config="{ filterable: true }"
        :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}">
      </en-table-column>
      <en-table-column title="支付金额" prop="paymentAmount"  :filter-sort-config="{ filterable: true }">
        <template slot-scope="scope">
          {{ Number(scope.row.paymentAmount) | thousand(2) }}
        </template>
      </en-table-column>
      <en-table-column title="用车部门" prop="userDeptName" :filter-sort-config="{ filterable: true }"> </en-table-column>
      <en-table-column title="关联行程" prop="tripId" width="180px" :filter-sort-config="{ filterable: true }"> </en-table-column>
      <en-table-column title="订单状态" prop="orderStatus" :filter-sort-config="{ filterable: true }">
        <template slot-scope="scope">
          <div v-if="scope.row.orderStatus === '001'" class="tagbox redtag">订失败</div>
          <div v-if="scope.row.orderStatus === '002'" class="tagbox yellowtag">预定中</div>
          <div v-if="scope.row.orderStatus === '006'" class="tagbox graytag">已取消</div>
        </template>
      </en-table-column>
      <en-table-column title="审批状态" prop="approveStatus"  :filter-sort-config="{ filterable: true }">
        <template slot-scope="scope">
          <div v-if="scope.row.approveStatus === '000'" class="tagbox cls000">未提交</div>
          <div v-if="scope.row.approveStatus === '001'" class="tagbox cls001">待处理</div>
          <div v-if="scope.row.approveStatus === '002'" class="tagbox cls002">审批中</div>
          <div v-if="scope.row.approveStatus === '006'" class="tagbox cls006">已审批</div>
          <div v-if="scope.row.approveStatus === '004'" class="tagbox cls004">已终止</div>
          <div v-if="scope.row.approveStatus === '003'" class="tagbox cls003">已结束</div>
          <div v-if="scope.row.approveStatus === '007'" class="tagbox cls007">已作废</div>
          <div v-if="scope.row.approveStatus === '008'" class="tagbox cls008">已提交</div>
        </template>
      </en-table-column>
       <template #paginationLeft>
         <div class="bottom">
          <span>订单数总计：{{ countNum }}</span>
          <span>消费金额总计：¥ {{ countMoney | thousand(2) }}</span>
        </div>
      </template>
    </en-table>
    <!-- <div class="right-page-info">
      <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
    </div>
    <div class="bottom">
      <span>订单数总计：{{ countNum }}</span>
      <span>消费金额总计：¥ {{ countMoney | thousand(2) }}</span>
    </div> -->
  </div>
</template>

<script>
import { request, thousand } from "en-js";
import dayjs from "dayjs";
import { orderService } from "@/api/businessTravel";
// import { foldersService } from "@/api/folders";

export default {
  filters: {
    thousand(value, decimal) {
      return thousand(value, decimal);
    }
  },
  data() {
    return {
      enLoading: null,
      // tab切换
      tabList: [
        {
          id: "flight",
          name: "机票"
        },
        {
          id: "hotel",
          name: "酒店"
        },
        {
          id: "car",
          name: "用车"
        }
      ],
      currentTab: "car", // 默认tab
      countNum: null, // 总条数
      countMoney: null, // 合计金额
      tableData: [], // 表格数据
      pageModel: {
        // 分页
        pageSize: 10,
        pageNo: 1,
        total: null,
        totalPages: null
      },
      queryData: {
        pageSize: 100, // 每页数
        pageNo: 1// 当前页码

      },
      // 下拉选择字段
      fileOptions: [
        { filename: "订单号" },
        { filename: "用车人" },
        { filename: "乘车人" },
        { filename: "出发时间" },
        { filename: "支付金额" },
        { filename: "用车部门" },
        { filename: "关联行程" },
        { filename: "订单状态" },
        { filename: "审批状态" }
      ],
      selFileValue: "", // 字段选中
      defaultProps: {
        label: "filename",
        value: "filename"
      },
      searchValue: ""// 搜索内容
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * @description: 初始化
     */
    @request(true, "enLoading", {
      subTitle: "数据加载中"
    })
    async init() {
      await this.queryCarList();
    },
    // 请求用车表格数据
    async queryCarList(pageNo = this.pageModel.pageNo, pageSize = this.pageModel.pageSize, data) {
      console.log(data);
      this.queryData.pageSize = pageSize;
      this.queryData.pageNo = pageNo;
      if (data?.column) {
        if (data.prop === "departureTime") {
          const array = data.value;
          this.queryData[data.prop] = `${dayjs(array[0]).format("YYYY-MM-DD")},${dayjs(array[1]).format("YYYY-MM-DD")}`;
        } else {
          this.queryData[data.prop] = data.value;
        }
      } else {
        this.queryData = {
          pageSize,
          pageNo
        };
      }
      try {
        this.loading = true;
        const res = await orderService.queryCarList(this.queryData);
        this.tableData = res.records;
        this.pageModel.total = res.total;
        this.pageModel.totalPages = res.totalPages;
        this.countNum = res.commParams.totalOrder;
        this.countMoney = res.commParams.totalAmount;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    /**
     *@description tab切换事件
     */
    changeTabs(res) {
      console.log(res);
      if (res.id !== "car") {
        this.$message("现有PC只有");
      }
    },
    /**
     * 表格筛选事件
     */
    handleFilterSortChange(data) {
      this.queryCarList(this.pageModel.pageNo, this.pageModel.pageSize, data);
      // this.showMessageWithTitle(data, "方式一: 表格总方法(建议)", "@filter-sort-change=\"tableFilterChange\"");
    },
    /**
     *@description 翻页事件
     * @param {pageNo,pageSize}
     * @return:
     */
    handlePageChanged({ pageNo, pageSize }) {
      // 调用查询列表数据方法
      this.queryCarList(pageNo, pageSize);
    },
    /*
    * 导出
    */
    exportClick() {
    }
  }
};
</script>

<style lang="scss" scoped>
.order-query /deep/ {
  position: relative;
  background-color: #ffffff !important;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .en-title-card {
    border-bottom: 1px solid #ffffff !important;
  }

  .en-title-card-right-container > div {
    display: flex;
    justify-content: flex-start;
    .button-export {
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      .en-icon {
        color: #26c393;
        margin: 8px 10px 8px 0;
      }
      .label {
        line-height: 32px;
        color: #636c78;
      }
    }
  }

  .en-tabs {
    background-color: #ffffff;
    /*border-bottom: 1px solid #dce5ec;*/
    .en-tabs-item {
      width: 120px;
      text-align: center;
    }
  }

  .main-table {
    position: relative;
    height: calc(100% - 105px);
    overflow: auto;

    .text-right {
      text-align: right;
    }

    .page-info {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 10px;
      -webkit-border-radius: 0 0 4px 4px;
      -moz-border-radius: 0 0 4px 4px;
      border-radius: 0 0 4px 4px;
      left: 200px;
      background-color: #ffffff;
      width: calc(100% - 210px);
      border-top: 1px solid #e8ecf2;
      .left-records-info {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .left-records-item {
          display: flex;
          justify-content: flex-start;
          color: #636c78;
          margin-right: 10px;
          line-height: 32px;
          font-size: 12px;
        }
      }

      .el-pagination__total {
        line-height: 32px;
      }
    }
  }
  .right-page-info {
    border-top: 1px solid #e8ecf2;
    height: 60px;
  }
  .bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    width: 50%;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    text-align: left;
    span {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .tagbox {
    height: 26px;
    line-height: 26px;
    width: 62px;
    text-align: center;
    color: #333;
    border-radius: 3px;
  }
  .redtag {
    color: #f76b6b;
    background: #fef0f0;
  }
  .greentag {
    color: #26c393;
    background: #e9f9f4;
  }
  .yellowtag {
    color: #ffad2c;
    background: #f8f1e4;
  }
  .graytag {
    color: #636c78;
    background: #eff0f1;
  }
  .cls000 {
    color: #7cafe6;
    background: #ebf4fd;
  }
  .cls001 {
    color: #ffad2c;
    background: #fff7ea;
  }
  .cls002 {
    color: #ffad2c;
    background: #fff7ea;
  }
  .cls006 {
    color: #4ccba0;
    background: #e9faf4;
  }
  .cls004 {
    color: #fa9492;
    background: #fef0f0;
  }
  .cls003 {
    color: #7b818d;
    background: #eff0f2;
  }
  .cls007 {
    color: #7b818d;
    background: #eff0f2;
  }
  .cls008 {
    color: #c6ecdf;
    background: #e9faf4;
  }
}
</style>
