<!--
 * @Author: Louis
 * @Date: 2019-09-03 09:48:45
 * @LastEditors: pengyu
 * @LastEditTime: 2020-06-07 16:47:25
 * @Description: 商旅管理-我的订单
 -->
<template>
  <div class="order-info">
    <en-title-card name="我的订单"></en-title-card>
    <div class="order-list">
      <en-table
        :data="list"
        border
        stripe
        @filter-sort-change="handleFilterSortChange"
        @row-click="tableRowClick"
      >
        <en-table-column
          v-for="item in columns"
          :key="item.dataName"
          :prop="item.dataName"
          :data="item"
          :width="item.width"
          :align="item.isMoney ? 'right' : 'left'"
          :filter-sort-config="{}"
        >
          <template slot-scope="scope">
            <span v-if="item.isTime">
              {{ $dayJS(scope.row[item.dataName]).format("MM-DD hh:mm:ss") }}
            </span>
            <span v-else-if="item.isMoney">
              {{ "￥" + scope.row[item.dataName] }}
            </span>
            <span v-else-if="item.isTarget">
              <!-- <el-tag :type="scope.row[item.dataName] === 1 ? 'success' : scope.row[item.dataName] === 2 ? '' : 'danger'">
                {{ scope.row[item.dataName] === 1 ? "订成功" : scope.row[item.dataName] === 2 ? "预订中" : "订失败" }}
              </el-tag> -->

              <en-trip-node-status
                :status="scope.row[item.dataName]"
              ></en-trip-node-status>
            </span>
            <div v-else-if="item.isType">
              <span>{{ scope.row[item.dataName] }}</span>
              <en-icon
                v-if="scope.row[item.dataName] === '酒店'"
                size="12px"
                class="gongxiang-icon"
                :style="{ fill: '#ffad2c' }"
                name="gongxiang-wodehangcheng"
              ></en-icon>
            </div>
            <span v-else>
              {{ scope.row[item.dataName] }}
            </span>
          </template>
        </en-table-column>
      </en-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderList",
  data() {
    return {
      list: [],
      columns: [
        {
          title: "行程分类",
          dataName: "type",
          isType: true,
          width: 182
        },
        {
          title: "行程时间",
          dataName: "dateTime",
          isTime: true,
          width: 199
        },
        {
          title: "概要",
          dataName: "description",
          noSort: 1, // 1排序 0不排序
          noSearch: 1, // 1筛选 0不筛选
          type: "001" // 文本
        },
        {
          title: "行程金额",
          dataName: "amount",
          isMoney: true,
          width: 192
        },
        {
          title: "行程状态",
          dataName: "status",
          isTarget: true,
          width: 115
        }
      ],
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        totalRecords: 0
      },
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    this.getTableData();
  },
  methods: {
    getTableData() {
      for (let i = 0; i < 50; i++) {
        this.list.push({
          type:
            Math.random() > 0.3
              ? "机票"
              : Math.random() > 0.6
                ? "酒店"
                : "用车",
          dateTime: new Date(),
          description:
            Math.random() > 0.3
              ? "概要就是几个字"
              : "概要就是几十个字,概要就是十个字,概要就是二十个字,概要就是三十个字",
          amount: (Math.random() * 23121).toFixed(2),
          status:
            Math.random() > 0.3 ? Math.floor(Math.random() * 18 + 1) : "018"
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    tableRowClick(row) {
      console.log(`-tableRowClick:${row.rowIndex}`);
      this.$router.push({ path: "/trip/tripDetail" });
    },
    showMessageWithTitle(
      {
        column,
        data,
        conditions,
        sortField
      },
      title,
      message
    ) {
      this.$notify({
        title,
        dangerouslyUseHTMLString: true,
        customClass: "notifyClass",
        message: `<div></br>
                <div><li>${message}</li></div>
                <div><li>列名: ${column.label} </li></div>
                <div><li>筛选数据: ${data} </li></div>
                <div><li>筛选条件: ${conditions} </li></div>
                <div><li>排序条件: ${sortField} </li></div>
                </div>`
      });
    },
    handleFilterSortChange(data) {
      this.showMessageWithTitle(
        data,
        "方式一: 表格总方法(建议)",
        "@filter-change='tableFilterChange'"
      );
    },
    handleTableFilterChange() {
      this.$message("我是en-table绑定方法实现");
    },
    handleColumnFilterChange() {
      this.$message("我是en-table-column列绑定方法实现");
    }
  }
};
</script>

<style lang="scss" scoped>
.order-info {
  .order-list {
    background-color: #ffffff;
    padding-top: 10px;
    // margin-top: 10px;
    height: calc(100% - 50px);
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    overflow: auto;

    .gongxiang-icon {
      margin-left: 10px;
    }

    // & /deep/ .vxe-header--column {
    //   text-align: left;
    // }
  }
}
</style>
