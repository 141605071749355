var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-info" },
    [
      _c("en-title-card", { attrs: { name: "我的订单" } }),
      _c(
        "div",
        { staticClass: "order-list" },
        [
          _c(
            "en-table",
            {
              attrs: { data: _vm.list, border: "", stripe: "" },
              on: {
                "filter-sort-change": _vm.handleFilterSortChange,
                "row-click": _vm.tableRowClick,
              },
            },
            _vm._l(_vm.columns, function (item) {
              return _c("en-table-column", {
                key: item.dataName,
                attrs: {
                  prop: item.dataName,
                  data: item,
                  width: item.width,
                  align: item.isMoney ? "right" : "left",
                  "filter-sort-config": {},
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.isTime
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .$dayJS(scope.row[item.dataName])
                                        .format("MM-DD hh:mm:ss")
                                    ) +
                                    " "
                                ),
                              ])
                            : item.isMoney
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s("￥" + scope.row[item.dataName]) +
                                    " "
                                ),
                              ])
                            : item.isTarget
                            ? _c(
                                "span",
                                [
                                  _c("en-trip-node-status", {
                                    attrs: { status: scope.row[item.dataName] },
                                  }),
                                ],
                                1
                              )
                            : item.isType
                            ? _c(
                                "div",
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[item.dataName])),
                                  ]),
                                  scope.row[item.dataName] === "酒店"
                                    ? _c("en-icon", {
                                        staticClass: "gongxiang-icon",
                                        style: { fill: "#ffad2c" },
                                        attrs: {
                                          size: "12px",
                                          name: "gongxiang-wodehangcheng",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.row[item.dataName]) + " "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }